'use client';

import { DEFAULT_LANG, SUPPORTED_LANGUAGES } from 'common/constants/common';

const getLanguageByUrl = () => {
  const path = window.location.pathname;
  const language = path.split('/')[1];

  return (
    SUPPORTED_LANGUAGES.find(supportedLang => supportedLang === language) ||
    DEFAULT_LANG
  );
};

export default getLanguageByUrl;
