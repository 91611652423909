import BaseFetcher from './baseFetcher';
import {
  ContentTypesEnum,
  HeadersEnum,
  HttpMethodsEnum
} from 'common/globalTypes/enums/http';
import { IDeleteProps, IGetProps, IPatchProps, IPostProps } from './types';
import createQueryParams from 'utils/helpers/createQueryParams';
import { defaultTypeOfCache } from 'common/constants/common';

class HttpService extends BaseFetcher {
  constructor(domain: string) {
    super(domain);
  }

  get<T>({
    url,
    queryParam,
    headerOptions = {},
    cache = defaultTypeOfCache,
    next,
    hideErrorMessage = false,
    responseModel
  }: IGetProps) {
    return this.request<T>({
      method: HttpMethodsEnum.GET,
      responseModel,
      url: `${url}${createQueryParams(queryParam)}`,
      hideErrorMessage,
      headerOptions: {
        ...headerOptions,
        [HeadersEnum.contentType]: ContentTypesEnum.applicationJson
      },
      cache,
      next
    });
  }

  post<T>({
    url,
    headerOptions = {},
    hideErrorMessage = false,
    data = {}
  }: IPostProps) {
    return this.request<T>({
      method: HttpMethodsEnum.POST,
      url,
      hideErrorMessage,
      headerOptions: {
        [HeadersEnum.contentType]: ContentTypesEnum.applicationJson,
        ...headerOptions
      },
      data
    });
  }

  patch<T>({ url, headerOptions = {}, data = {} }: IPatchProps) {
    return this.request<T>({
      method: HttpMethodsEnum.PATCH,
      url,
      headerOptions: {
        [HeadersEnum.contentType]: ContentTypesEnum.applicationJson,
        ...headerOptions
      },
      data
    });
  }

  delete<T>({ url, headerOptions = {} }: IDeleteProps) {
    return this.request<T>({
      method: HttpMethodsEnum.DELETE,
      url,
      headerOptions: {
        [HeadersEnum.contentType]: ContentTypesEnum.applicationJson,
        ...headerOptions
      }
    });
  }
}

export default HttpService;
