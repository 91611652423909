import { useTranslations as useTranslationsNextIntel } from 'next-intl';

const useTranslations = (transKey?: string) => {
  const t = useTranslationsNextIntel(transKey || null);

  return {
    t
  };
};

export default useTranslations;
