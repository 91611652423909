'use client';

import { FC } from 'react';

import { IShowElementProps } from './types';

const ShowElement: FC<IShowElementProps> = ({
  isShow = true,
  show = null,
  children
}) => {
  const component = isShow ? children : show;

  return <>{component}</>;
};

export default ShowElement;
