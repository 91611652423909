import HttpService from 'infrastructure/httpService';
import { RequestEnum } from 'common/globalTypes/enums/http';
import { EnvConfig } from 'configs/env';

class BaseApiHandler {
  public readonly request: Record<RequestEnum, HttpService>;

  constructor() {
    this.request = {
      [RequestEnum.accountsFetcher]: new HttpService(EnvConfig.accountsBackend),
      [RequestEnum.applicationsFetcher]: new HttpService(
        EnvConfig.appStoreBackend
      )
    };
  }
}

export default BaseApiHandler;
