import isServerSide from 'utils/helpers/isServerSide';
import {
  notValidAuthorizationTokenCallbackApi,
  unauthorizedStatus
} from 'common/constants/authorization';

const fetcherErrorHandler = async (
  response: Response,
  hideErrorMessage?: boolean
) => {
  if (response.status === unauthorizedStatus) {
    if (isServerSide) {
      window.location.href = notValidAuthorizationTokenCallbackApi;
    }
  }

  //handling unauthorized, forbidden, not-found ,  maintenance and backend errors
  if (!response.ok) {
    const errorRes = await response.json();
    //this condition temporary because we don't know now error responses from backend, and this error we catch inside react query config :))
    const message =
      errorRes?.message ||
      errorRes?.error?.description ||
      errorRes?.type?.description ||
      errorRes?.data?.error ||
      'Something went wrong';

    throw {
      message,
      hideErrorMessage,
      statusCode: response.status
    };
  }

  return response;
};

export default fetcherErrorHandler;
