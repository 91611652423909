import { PartialRecord } from 'common/globalTypes/utilityTypes/common';
import { LangMappingEnum, RoutesEnum } from 'common/globalTypes/enums/common';
import { LanguagesType } from '../globalTypes/types/common';
import { EnvConfig } from 'configs/env';

export const DEFAULT_LANG = LangMappingEnum.en;
export const SUPPORTED_LANGUAGES: LanguagesType[] =
  Object.values(LangMappingEnum);

export const LANG_MAPPING: PartialRecord<
  keyof typeof LangMappingEnum,
  LangMappingEnum
> = {
  en: LangMappingEnum.en
};

export const Routes = {
  HOME: {
    pathname: RoutesEnum.HOME
  },
  SEARCH: {
    pathname: RoutesEnum.SEARCH
  },
  CATEGORY: {
    pathname: RoutesEnum.CATEGORY
  },
  CHECKOUT: {
    pathname: RoutesEnum.CHECKOUT
  }
};

export const pageAndLimitDefaultValue = {
  page: 1,
  limit: 12,
  first: 10
};

export const queryParamRedirectedFromDashboard = 'projectId';

export const defaultTypeOfCache = 'default';

export const scrollableTargetForSearchDropDown = 'searchDropDown';

export const defaultCurrency = '$';

export const developerName = 'developedByUcraft';

export const defaultCachingTime = 50000;

export const mainFooterId = 'main-footer';
export const ucraftNextLink = 'https://next.ucraft.com/';

export const appStoreIcons = [
  {
    rel: 'apple-touch-icon',
    url: `${EnvConfig.storageUrl}/ucraft-icons/favicons/light-mode/apple-touch-icon.png`,
    media: '(prefers-color-scheme: light)',
    sizes: '180x180'
  },
  {
    rel: 'icon',
    type: 'image/png',
    url: `${EnvConfig.storageUrl}/ucraft-icons/favicons/light-mode/favicon-32x32.png`,
    media: '(prefers-color-scheme: light)',
    sizes: '32x32'
  },
  {
    rel: 'icon',
    type: 'image/png',
    url: `${EnvConfig.storageUrl}/ucraft-icons/favicons/light-mode/favicon-16x16.png`,
    media: '(prefers-color-scheme: light)',
    sizes: '16x16'
  },
  // {
  //   rel: 'manifest',
  //   url: `${EnvConfig.storageUrl}/ucraft-icons/favicons/light-mode/site.webmanifest`,
  //   media: '(prefers-color-scheme: light)'
  // },
  {
    rel: 'mask-icon',
    url: `${EnvConfig.storageUrl}/ucraft-icons/favicons/light-mode/safari-pinned-tab.svg`,
    color: '#5bbad5',
    media: '(prefers-color-scheme: light)'
  },
  {
    rel: 'apple-touch-icon',
    url: `${EnvConfig.storageUrl}/ucraft-icons/favicons/dark-mode/apple-touch-icon.png`,
    media: '(prefers-color-scheme: dark)',
    sizes: '180x180'
  },
  {
    rel: 'icon',
    type: 'image/png',
    url: `${EnvConfig.storageUrl}/ucraft-icons/favicons/dark-mode/favicon-32x32.png`,
    media: '(prefers-color-scheme: dark)',
    sizes: '32x32'
  },
  {
    rel: 'icon',
    type: 'image/png',
    url: `${EnvConfig.storageUrl}/ucraft-icons/favicons/dark-mode/favicon-16x16.png`,
    media: '(prefers-color-scheme: dark)',
    sizes: '16x16'
  },
  // {
  //   rel: 'manifest',
  //   url: `${EnvConfig.storageUrl}/ucraft-icons/favicons/dark-mode/site.webmanifest`,
  //   media: '(prefers-color-scheme: dark)'
  // },
  {
    rel: 'mask-icon',
    url: `${EnvConfig.storageUrl}/ucraft-icons/favicons/dark-mode/safari-pinned-tab.svg`,
    color: '#5bbad5',
    media: '(prefers-color-scheme: dark)'
  }
  // {
  //   rel: 'preload',
  //   type: 'image/webp',
  //   as: 'image',
  //   url: `${EnvConfig.metaDataBaseUrl}/assets/images/webp/app-mobile.webp`
  // }
];

export const RUDDERSTUCK_ANONYMOUS_ID = 'RUDDERSTUCK_ANONYMOUS_ID';
export const RUDDERSTUCK_SESSION_ID = 'RUDDERSTUCK_SESSION_ID';
export const USER_IDENTIFIED = 'userIdentified';
export const USER_VERIFIED = 'userVerified';
