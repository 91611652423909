import { QueryModel } from 'common/globalTypes/utilityTypes/common';

const createQueryParams = (params?: QueryModel): string => {
  if (!params) {
    return '';
  }

  const buildQueryString = (obj: any, parentKey?: string): string => {
    return Object.keys(obj)
      .map(key => {
        const nestedKey = parentKey ? `${parentKey}[${key}]` : key;
        const value = obj[key];

        if (typeof value === 'object' && value !== null) {
          return buildQueryString(value, nestedKey);
        } else {
          return `${encodeURIComponent(nestedKey)}=${encodeURIComponent(
            value
          )}`;
        }
      })
      .join('&');
  };

  const queryParams = buildQueryString(params);

  return queryParams ? `?${queryParams}` : '';
};

export default createQueryParams;
