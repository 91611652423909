import {
  RUDDERSTUCK_ANONYMOUS_ID,
  RUDDERSTUCK_SESSION_ID
} from 'common/constants/common';
import isServerSide from './isServerSide';
import { HeadersEnum } from 'common/globalTypes/enums/http';

export const getAnalyticsHeaders = () => {
  let sessionId;
  let anonymousId;

  if (isServerSide) {
    sessionId = localStorage.getItem(RUDDERSTUCK_SESSION_ID);
    anonymousId = localStorage.getItem(RUDDERSTUCK_ANONYMOUS_ID);
  }

  return {
    [HeadersEnum.xAnonymousId]: anonymousId,
    [HeadersEnum.xSessionId]: sessionId
  };
};
